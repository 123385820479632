/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, lazy, useContext, type JSX, type PropsWithChildren } from "react";

const Stackables = lazy(() => import("./providers/stackables.io"));
const Vioxoo = lazy(() => import("./providers/vioxoo.com"));
const Vozuu = lazy(() => import("./providers/vozuu.com"));

interface WhitelabelContextInterface {
    translation?: any | undefined;
    Logo: (props: {
        variant: "login-quote" | "login" | "header-logo" | "header-logo-text";
        className?: string;
    }) => JSX.Element;
}

export const WhitelabelContext = createContext<WhitelabelContextInterface>(
    undefined as unknown as WhitelabelContextInterface
);

export function Whitelabel(props: PropsWithChildren) {
    const Theme = window.location.hostname.includes("voixoo")
        ? Vioxoo
        : window.location.hostname.includes("vozuu")
          ? Vozuu
          : Stackables;

    return <Theme>{props.children}</Theme>;
}

export function useWhitelabel() {
    const context = useContext(WhitelabelContext);

    if (!context) {
        throw new Error("Context not found");
    }

    return context;
}
