import { useCachedUrqlRefresh } from "@/components/UrqlProviderContext/hooks/useCachedUrqlClient";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { createContext, useContext, useMemo, useState, type PropsWithChildren } from "react";
import SimplePullToRefresh from "react-simple-pull-to-refresh";

interface RefreshContextInterface {
    disable: () => void;
    enable: () => void;
    toggle: (enabled?: boolean) => void;
}

export const RefreshContext = createContext<RefreshContextInterface>(undefined as unknown as RefreshContextInterface);

export function PullToRefresh({ children }: PropsWithChildren) {
    const { refresh } = useCachedUrqlRefresh();
    const touch = useMediaQuery("(hover: none)");
    const [refreshDisabled, setRefreshDisabled] = useState(false);

    const handleRefresh = async () => {
        refresh();
        await new Promise((resolve) => setTimeout(resolve, 1000));
    };

    const contextValue = useMemo<RefreshContextInterface>(() => {
        return {
            disable: () => {
                console.log("disable");
                setRefreshDisabled(true);
            },
            enable: () => {
                console.log("enable");
                setRefreshDisabled(false);
            },
            toggle: (enabled?: boolean) => {
                const f = enabled !== undefined ? !enabled : !refreshDisabled;
                console.log("toggle", f);
                setRefreshDisabled(f);
            },
        };
    }, [refreshDisabled, setRefreshDisabled]);

    const isPullable = touch && !refreshDisabled;
    console.log("isPullable", isPullable);

    return (
        <SimplePullToRefresh
            onRefresh={handleRefresh}
            className="pull-to-refresh"
            isPullable={isPullable}
            pullingContent={<></>}
        >
            <RefreshContext.Provider value={contextValue}>
                <>{children}</>
            </RefreshContext.Provider>
        </SimplePullToRefresh>
    );
}

export function useRefreshContext(): RefreshContextInterface {
    const context = useContext(RefreshContext);

    if (!context) {
        throw new Error("RefreshContext not found");
    }

    return context;
}
