import { useState, type PropsWithChildren } from "react";
import { toast } from "sonner";
import timestring from "timestring";
import { useRegisterSW } from "virtual:pwa-register/react";

// import i18n (needs to be bundled ;))

const intervalMS = timestring("10 seconds", "ms");

export function ReloadPrompt(props: PropsWithChildren) {
    const [relaoding, setReloading] = useState(false);
    const { updateServiceWorker } = useRegisterSW({
        onRegisteredSW(swUrl, r) {
            setInterval(async () => {
                if (!r) return;

                if (r.installing || !navigator) return;

                if ("connection" in navigator && !navigator.onLine) return;

                console.log("Checking for updates...");

                const resp = await fetch(swUrl, {
                    cache: "no-store",
                    headers: {
                        cache: "no-store",
                        "cache-control": "no-cache",
                    },
                });

                if (resp?.status === 200) await r.update();
            }, intervalMS);
        },
        onNeedRefresh() {
            toast("New Version Available", {
                id: "new-version",
                description: "Reload the page to apply new version.",
                action: {
                    label: "Refresh",
                    onClick: () => {
                        setReloading(true);
                        updateServiceWorker(true);
                    },
                },
                duration: Infinity,
            });
        },
    });

    if (relaoding) return null;

    return <>{props.children}</>;
}
