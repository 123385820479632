import { RefreshButton } from "@/components/PortalHeader/RefreshButton";
import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { usePortalNavigation, type NavigationItemRuntime } from "@/hooks/usePortalNavigation";
import { cn } from "@/lib/utils";
import useSize from "@react-hook/size";
import { Check, MoreHorizontal } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router";

function TabButton(props: { base: string; link: NavigationItemRuntime; selected?: string }) {
    const classes: string[] = [];
    if (props.link.selected) classes.push("border-b-2 border-amber-600");

    return (
        <div className={cn("", classes.join(" "))}>
            <Button size="sm" variant="ghost" className="h-7 rounded-[8px]" asChild>
                <Link to={props.link.fullLink}>{props.link.name}</Link>
            </Button>
        </div>
    );
}

function getTextWidth(text: string) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
        return -1;
    }

    context.font = getComputedStyle(document.body).font;

    return context.measureText(text).width;
}

export function TabNavigation() {
    const { base, action, links } = usePortalNavigation();
    const target = useRef<HTMLDivElement>(null);
    const [width] = useSize(target as any);
    const [visible, setVisible] = useState(0);

    useEffect(() => {
        if (!width) return;

        let total = 0;
        let show = 0;
        for (const link of links) {
            total += getTextWidth(link.name);
            total += 24; // button margins
            // total += 8; // button spacing

            const endc = show + 1 < links.length ? 48 : 0;

            // console.log("test", show, total, endc, width, total + endc > width);

            if (total + endc > width) break;
            show++;
        }

        if (show !== visible) {
            setVisible(show);
        }
    }, [links, visible, width]);

    const selectedOutOfView = links.findIndex((link) => link.selected) >= visible;

    return (
        <div className="flex flex-row h-8 mt-2 space-x-2" ref={target}>
            <div className="flex flex-row space-x-2 flex-grow overflow-hidden">
                {links.slice(0, visible).map((link) => (
                    <TabButton key={base + link.action} link={link} base={base} selected={action} />
                ))}
            </div>
            <RefreshButton />
            {visible < links.length && (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            size="icon"
                            variant="outline"
                            className={cn(
                                "min-h-10 min-w-10 rounded-[8px] md:hidden",
                                selectedOutOfView ? "border-amber-600" : ""
                            )}
                        >
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="min-w-60">
                        {links.map((link) => (
                            <DropdownMenuItem key={link.fullLink} asChild className="cursor-pointer">
                                <Link to={link.fullLink}>
                                    <span className="flex flex-grow">{link.name}</span>
                                    {link.selected && <Check className="w-4 h-4 text-muted-foreground" />}
                                </Link>
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </div>
    );
}
