import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export interface NavigationItem {
    name: string;
    action?: string;
    icon?: SVGSVGElement;
    permissions?: string[];
}

export interface NavigationItemRuntime extends NavigationItem {
    fullLink: string;
    selected: boolean;
}

export function usePortalNavigation() {
    const location = useLocation();
    const { t } = useTranslation("shared");

    const navigation: Record<string, NavigationItem[]> = useMemo(
        () => ({
            user: [
                {
                    name: t("navigation.user.overview", "My teams"),
                },
                {
                    name: t("navigation.user.settings", "User settings"),
                    action: "settings",
                },
            ],
            account: [
                {
                    name: t("navigation.account.projects", "Workspaces"),
                },
                {
                    name: t("navigation.account.environments", "Environments"),
                    action: "environments",
                    permissions: ["environments"],
                },
                {
                    name: t("navigation.account.users", "Users"),
                    action: "users",
                    permissions: ["users"],
                },
                {
                    name: t("navigation.account.workers", "Workers"),
                    action: "workers",
                    permissions: ["workers"],
                },
                {
                    name: t("navigation.account.permissions", "Settings"),
                    action: "permissions",
                    permissions: ["admin"],
                },
            ],
            d: [
                {
                    name: t("navigation.d.data", "Data"),
                },
                {
                    name: t("navigation.d.schema", "Schema"),
                    action: "schema",
                },
                {
                    name: t("navigation.d.apiconnect", "API connect"),
                    action: "connect",
                },
                {
                    name: t("navigation.d.webhooks", "Webhoooks"),
                    action: "webhooks",
                },
                {
                    name: t("navigation.d.collections", "Collections"),
                    action: "collections",
                },
                {
                    name: t("navigation.d.permissions", "Permissions"),
                    action: "permissions",
                },
                {
                    name: t("navigation.d.usage", "Usage"),
                    action: "usage",
                },
            ],
            c: [
                {
                    name: t("navigation.c.applications", "Applications"),
                },
            ],
            s: [
                {
                    name: t("navigation.s.overview", "Overview"),
                },
                // {
                //     name: t("navigation.s.reports", "Reports"),
                //     action: "reports",
                // },
                {
                    name: t("navigation.s.surveys", "Surveys"),
                    action: "surveys",
                },
            ],
        }),
        [t]
    );

    return useMemo(() => {
        const [navigationPath, actionPath] = location.pathname.split("/-/");
        const [, account, type, project, env, overlay, ...zoom] = navigationPath.split("/");
        const [action] = actionPath?.split("/") ?? [];

        let base = "";
        let links = navigation.user;

        if (account) {
            base += `/${account}`;
            links = navigation.account;
        }

        if (project) {
            base += `/${type}/${project}`;
            links = [];
        }

        if (env) {
            base += `/${env}`;
            links = navigation[type];
        }

        if (overlay) {
            base += `/${overlay}`;
        }

        const checkPermission = (link: NavigationItem) => {
            if (!link.permissions) return true;
            return link.permissions.some((permission) => {
                if (permission === "admin") {
                    return true;
                }

                return false;
            });
        };

        return {
            account,
            project,
            type: type as "c" | "d" | "s",
            env,
            overlay,
            zoom,
            base,
            links: links.filter(checkPermission).map((link) => ({
                ...link,
                fullLink: base + (link.action ? `/-/${link.action}` : ""),
                selected: link.action?.startsWith(action) || (!link.action && !action),
            })) as NavigationItemRuntime[],
            action,
            changeBranch(nenv: string, noverlay: string) {
                const token = `/${env}/${overlay}/`;
                if (location.pathname.includes(token)) {
                    return location.pathname.replace(token, `/${nenv}/${noverlay}/`);
                } else {
                    return `/${account}/${type}/${project}/${nenv}/${noverlay}/`;
                }
            },
            changeAccount(naccount: string, ntype: string, nproject: string) {
                if (naccount === account && ntype === type && nproject === project) {
                    return location.pathname;
                }

                const finalLocation = [naccount, ntype, nproject];
                // accounts have different environemtns
                if (account !== naccount) {
                    return finalLocation.join("/");
                }

                if (env) finalLocation.push(env);
                if (overlay) finalLocation.push(overlay);

                // actions are different per project type
                if (type !== ntype) {
                    return finalLocation.join("/");
                }

                // even if action is taken then agains an entity that does not exist int he different project
                if (zoom.length) {
                    return finalLocation.join("/");
                }

                // append action if it exists
                if (actionPath) finalLocation.push("-", actionPath);

                return finalLocation.join("/");
            },
        };
    }, [location.pathname, navigation]);
}
