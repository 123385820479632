// Generouted, changes to this file will be overridden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/-/auth/callback`
  | `/-/auth/email/:id`
  | `/-/settings/*`
  | `/-/survey/:account/:project/:env/:overlay/i/:invite`
  | `/:account`
  | `/:account/-/environments`
  | `/:account/-/permissions`
  | `/:account/c/:project`
  | `/:account/c/:project/:env/:overlay`
  | `/:account/d/:project`
  | `/:account/d/:project/:env/:overlay`
  | `/:account/d/:project/:env/:overlay/-/collections`
  | `/:account/d/:project/:env/:overlay/-/connect`
  | `/:account/d/:project/:env/:overlay/-/permissions`
  | `/:account/d/:project/:env/:overlay/-/schema`
  | `/:account/d/:project/:env/:overlay/-/usage`
  | `/:account/d/:project/:env/:overlay/-/webhooks`
  | `/:account/d/:project/:env/:overlay/:entity`
  | `/:account/d/:project/:env/:overlay/:entity/*`
  | `/:account/s/:project`
  | `/:account/s/:project/:env/:overlay`
  | `/:account/s/:project/:env/:overlay/-/surveys`
  | `/:account/s/:project/:env/:overlay/-/surveys/:surveyid`

export type Params = {
  '/-/auth/email/:id': { id: string }
  '/-/settings/*': { '*': string }
  '/-/survey/:account/:project/:env/:overlay/i/:invite': { account: string; project: string; env: string; overlay: string; invite: string }
  '/:account': { account: string }
  '/:account/-/environments': { account: string }
  '/:account/-/permissions': { account: string }
  '/:account/c/:project': { account: string; project: string }
  '/:account/c/:project/:env/:overlay': { account: string; project: string; env: string; overlay: string }
  '/:account/d/:project': { account: string; project: string }
  '/:account/d/:project/:env/:overlay': { account: string; project: string; env: string; overlay: string }
  '/:account/d/:project/:env/:overlay/-/collections': { account: string; project: string; env: string; overlay: string }
  '/:account/d/:project/:env/:overlay/-/connect': { account: string; project: string; env: string; overlay: string }
  '/:account/d/:project/:env/:overlay/-/permissions': { account: string; project: string; env: string; overlay: string }
  '/:account/d/:project/:env/:overlay/-/schema': { account: string; project: string; env: string; overlay: string }
  '/:account/d/:project/:env/:overlay/-/usage': { account: string; project: string; env: string; overlay: string }
  '/:account/d/:project/:env/:overlay/-/webhooks': { account: string; project: string; env: string; overlay: string }
  '/:account/d/:project/:env/:overlay/:entity': { account: string; project: string; env: string; overlay: string; entity: string }
  '/:account/d/:project/:env/:overlay/:entity/*': { account: string; project: string; env: string; overlay: string; entity: string; '*': string }
  '/:account/s/:project': { account: string; project: string }
  '/:account/s/:project/:env/:overlay': { account: string; project: string; env: string; overlay: string }
  '/:account/s/:project/:env/:overlay/-/surveys': { account: string; project: string; env: string; overlay: string }
  '/:account/s/:project/:env/:overlay/-/surveys/:surveyid': { account: string; project: string; env: string; overlay: string; surveyid: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
