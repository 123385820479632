import { ReloadPrompt } from "@/components/infra/updates";
import { ThemeProvider } from "@/components/ui/addon/theme-provider";
import { Toaster } from "@/components/ui/sonner";
import { Whitelabel } from "@/whitelabel/Whitelabel";
import { routes } from "@generouted/react-router/lazy";
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter } from "react-router";
import { RouterProvider } from "react-router/dom";
import "./i18n";

export const router: ReturnType<typeof createBrowserRouter> = createBrowserRouter(routes);

createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <HelmetProvider>
            <Suspense fallback={null}>
                <Whitelabel>
                    <ThemeProvider defaultTheme="system" storageKey="ui-theme">
                        <Suspense>
                            <ReloadPrompt>
                                <RouterProvider router={router} />
                            </ReloadPrompt>
                            <Toaster />
                        </Suspense>
                    </ThemeProvider>
                </Whitelabel>
            </Suspense>
        </HelmetProvider>
    </StrictMode>
);
